import { Component, ElementRef, ViewChild } from '@angular/core';
import { ShopService } from '../shop/shop.service';
import { Product } from '../shared/models/product';
import { ShopParams } from '../shared/models/shopParams';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AccountService } from '../account/account.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  imagesIndustrial: Product[] = [];
    imagesModa:Product[] = [];
    imagesSalud: Product[] = [];
    @ViewChild('search') searchTerm?: ElementRef;
    shopParams: ShopParams;
    url: string;
    totalSlides = [1,2,4,6,8,9]; // Total number of slides
    urlReg = environment.contentUrl + "images/pages/3.jpg";

  constructor(private shopService: ShopService, private router:Router,public accountService: AccountService) {
    this.url = environment.contentUrl + "images/banner/";
    this.getProducts();
    this.shopParams = shopService.getShopParams();

  }

 

  getProducts() {
    this.shopService.getProducts()
    .subscribe({
      next: response => {

        this.imagesModa = response.data.filter( x=> x.category1 == "Moda y Belleza ");
        this.imagesSalud = response.data.filter( x=> x.category1 == "Salud");
        this.imagesIndustrial = response.data.filter( x=> x.category1 == "Industrial");
       console.log(response.data);
      },
      error: error => console.log(error)
    })
  }


  onSearch() {
    const params = this.shopService.getShopParams();
    params.search = this.searchTerm?.nativeElement.value;
    params.pageNumber = 1;
    this.shopService.setShopParams(params);
    this.shopParams = params;
    this.getProducts();
    this.router.navigate(['/shop']);
  }

  onReset() {
    if (this.searchTerm) this.searchTerm.nativeElement.value = '';
    this.shopParams = new ShopParams();
    this.shopService.setShopParams(this.shopParams);
    this.getProducts();
  }

}
