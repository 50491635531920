
<!--<div class="top-ranking">
    <h2>Top Ranking</h2>
    <ul class="products-list">
      <li *ngFor="let product of products">
        <div class="product-card">
          <div class="product-image">
            <img [src]="product.pictureUrl" alt="{{ product.name }}">
          </div>
          <div class="product-details">
            <h3>{{ product.name }}</h3>
            <p>{{ product.description }}</p>
            <span class="product-price">Precio: ${{ product.price }}</span>
            <button>Ver detalles</button>
          </div>
        </div>
      </li>
    </ul>
  </div>-->

  <p-card styleClass="text-center" header="Mejor Ranking">
    <p-carousel [value]="products" [numVisible]="1" [numScroll]="1"  [circular]="true" [responsiveOptions]="responsiveOptions" autoPlayInterval="3000">
        <ng-template let-product pTemplate="item">
            <div class="product-item">
                <div class="product-item-content">
                    <div class="row">
                      <div class="col-12">
                        <img src="{{ product.pictureUrl }}" [alt]="product.name" class="product-image1" />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-4">
                        <img src="{{ product.pictureUrl }}" [alt]="product.name" class="product-image" />
                      </div>
                      <div class="col-4">
                        <img src="{{ product.pictureUrl }}" [alt]="product.name" class="product-image" />
                      </div>
                      <div class="col-4">
                        <img src="{{ product.pictureUrl }}" [alt]="product.name" class="product-image" />
                      </div>
                    </div>

                </div>
            </div>
        </ng-template>
    </p-carousel>
</p-card >


 



 
  
    