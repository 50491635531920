<div class="customer-support-container">
    <div class="content-wrapper">
        <h2>Atención a Clientes</h2>

        <div class="image-container">
            <img src="{{url}}" alt="Imagen de Atención al Cliente" />
        </div>
        <p>
            ¡Estamos para ti en lo que necesites! Cuenta con nosotros para cualquier tema logístico, de transacción o de
            lo que se ofrezca dentro de tu proceso de compra con Viammex.
        </p>

        <p>
            Recuerda que nuestro equipo estará disponible de 8 am a 5 pm en el teléfono
            <span class="phone-number">666555666</span>
            o nos puede mandar un mail a
            <a href="mailto:atencionaclientes@viammex.com">atencionaclientes@viammex.com</a>
            y contestaremos en menos de 24 horas.
        </p>

        <p>
            Nosotros podemos ayudar a resolver los temas a resolver entre proveedor y cliente siempre y cuando sea de
            una transacción hecha a través de nuestra plataforma.
        </p>
    </div>
</div>