import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ProfileService } from '../profile.service';
import { DomSanitizer } from '@angular/platform-browser';
import { bannerIMG } from 'src/app/shared/models/bannerIMG';

@Component({
  selector: 'app-company-overview',
  templateUrl: './company-overview.component.html',
  styleUrls: ['./company-overview.component.scss'],
  providers: [MessageService,ConfirmationService ]
})


export class CompanyOverviewComponent implements OnInit {

  companyDataPrueba: CompanyData = {
    nombreCompletoResponsableCuenta: "Juan Pérez López",
    email: "juan.perez@ejemplo.com",
    telefono: "+52 55 1234 5678",
    ineRepresentanteLegal: "https://example.com/ine-representante-legal.jpg", // URL o archivo
    ineResponsableManejoCuenta: "https://example.com/ine-responsable-cuenta.jpg", // URL o archivo
    direccion: "Calle Mayor 123, Colonia Centro, Ciudad, Estado, CP",
    rfc: "XAXX010101AAA",
    razonSocial: "Empresa Ejemplo S.A. de C.V.",
    nombreComercial: "Tienda Ejemplo",
    direccionComercial: "Calle Principal 456, Colonia Norte, Ciudad, Estado, CP",
    capacidadProduccion: "100 unidades por día",
    fotosFachada: [],
    regimenFiscal: "601 - General de Ley Personas Morales",
    datosBancarios: "JSON con detalles de la cuenta bancaria",
    giroComercial: "Venta de ropa y accesorios",
    // ... otros valores de prueba para las propiedades adicionales
  };

  constructor( private messageService: MessageService,private profileService: ProfileService,
    public domSanitizer: DomSanitizer, private confirmationService: ConfirmationService ) {

  }

  
ngOnInit(): void {


  this.profileService.getBanners()
 .subscribe({
   next: response => {
     this.companyDataPrueba.fotosFachada = response;
   },
   error: error => console.log(error)
 })

}
  
}



export interface CompanyData {
  nombreCompletoResponsableCuenta: string;
  email: string;
  telefono: string;
  ineRepresentanteLegal: string; // Considerar un tipo File u otro formato
  ineResponsableManejoCuenta: string; // Considerar un tipo File u otro formato
  direccion: string;
  rfc: string;
  razonSocial: string;
  nombreComercial: string;
  direccionComercial: string;
  capacidadProduccion: string; // O un número si es apropiado
  fotosFachada: string[] | File[] | bannerIMG[]; // Array para múltiples fotos
  regimenFiscal: string;
  datosBancarios: string; // ¿Quizás un objeto para los detalles bancarios?
  giroComercial: string;
  // ... otras propiedades que podrías necesitar para la vista general
}