<carousel [isAnimated]="true">
    <slide *ngFor="let slideNumber of totalSlides">
        <img src="{{ url }}{{ slideNumber }}.jpg" alt="slide {{ slideNumber }}" style="display: block; width: 100%;">
    </slide>
</carousel>
<div class="d-flex mt-2 offset-lg-2 col-lg-8 col-sm-8 offset-sm-2">

    <input (keyup.enter)="onSearch()" type="text" placeholder="Buscar" class="form-control me-2" #search>
    <button (click)="onSearch()" class="btn btn-outline-primary mx-2">Buscar</button>
    <button (click)="onReset()" class="btn btn-outline-success">Reiniciar</button>
</div>
<section class="registration-info top-section" *ngIf="(accountService.currentUser$ | async) === null">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="image-container">
                    <img src="{{urlReg}}" alt="Registro de Proveedor en Viammex">
                </div>
            </div>
            <div class="col-md-4 d-flex flex-column justify-content-center">
                <h3>¿Listo para unirte a Viammex?</h3>
                <p>
                    Buscamos en el logotipo de una persona, a un costado de Atención a cliente y en menú desplegable
                    encontraremos la opción de hacer registro de Cliente. Para hacer una cuenta es muy sencillo, solo
                    pedimos tu mail, tu teléfono, tu nombre completo y una contraseña. Para empezar a vender hay que
                    verificar el perfil del vendedor y/o la empresa dentro de VIAMMEX con datos más específicos.
                </p>
                <p>
                    Una vez hayas verificado tu cuenta, puedes ver los detalles de tu página, subir productos a ventas y
                    recibir ordenes de compra para cumplir en caso de que lo aceptes.
                </p>
                <a routerLink="/account/register/provider" class="btn btn-primary">Inscripción de Vendedor</a>
            </div>

        </div>
    </div>
</section>
<section class="featured">
    <div class="d-flex justify-content-center pt-4">
        <div class="row">
            <div class="col-lg-3 col-12 col-md-10 offset-md-1">
                <app-top-ranking></app-top-ranking>
            </div>
            <div class="col-lg-3 col-12 col-md-10 offset-md-1">
                <app-new-arrivals></app-new-arrivals>
            </div>
            <div class="col-lg-3 col-12 col-md-10 offset-md-1">
                <app-deals></app-deals>
            </div>
        </div>
    </div>
</section>


<section class="featured">

    <div class="row">
        <div class="col-lg-3 col-12 col-md-10 offset-md-1">
            <p-card header="Descubre tu estilo único">
                <p-galleria [value]="imagesModa">
                    <ng-template pTemplate="item" let-item>
                        <img [src]="item.pictureUrl" style="width: 100%; display: block; max-height: 30vh;" />

                    </ng-template>
                </p-galleria>
                <p>Explora las últimas tendencias en moda, maquillaje, cuidado de la piel y productos de belleza para
                    realzar tu estilo único.</p>
                <ng-template pTemplate="footer">
                    <p-button label="Ver más" styleClass="p-button-raised"></p-button>
                </ng-template>
            </p-card>
        </div>



        <div class="col-lg-3 col-12 col-md-10 offset-md-1">
            <p-card header="Soluciones industriales de vanguardia">
                <p-galleria [value]="imagesIndustrial">
                    <ng-template pTemplate="item" let-item>
                        <img [src]="item.pictureUrl" style="width: 100%; display: block; max-height: 30vh" />
                    </ng-template>
                </p-galleria>
                <p>Encuentra maquinaria, herramientas y equipos de alta calidad para optimizar tus procesos
                    industriales.</p>
                <ng-template pTemplate="footer">
                    <p-button label="Ver más" styleClass="p-button-raised"></p-button>
                </ng-template>
            </p-card>
        </div>

        <div class="col-lg-3 col-12 col-md-10 offset-md-1">
            <p-card header="Cuida tu salud y bienestar">
                <p-galleria [value]="imagesSalud">
                    <ng-template pTemplate="item" let-item>
                        <img [src]="item.pictureUrl" style="width: 100%; display: block; max-height: 30vh;" />
                    </ng-template>
                </p-galleria>
                <p>Descubre productos y servicios para mantener un estilo de vida saludable y activo.</p>
                <ng-template pTemplate="footer">
                    <p-button label="Ver más" styleClass="p-button-raised"></p-button>
                </ng-template>
            </p-card>
        </div>
    </div>
</section>