import * as cuid from 'cuid';

export interface BasketItem {
    id: number;
    productName: string;
    price: number;
    quantity: number;
    pictureUrl: string;
    category1: string;
    providerId: string; // Asegúrate de agregar el providerId a BasketItem

}

export interface Basket {
    id: string;
    items: BasketItem[];
    clientSecret?: string;
    paymentIntentIds: { [providerId: string]: string }; // Diccionario para guardar los PaymentIntentId por proveedor
    deliveryMethodId?: number;
    shippingPrice: number;
}

export class Basket implements Basket {
    id = cuid();
    items: BasketItem[] = [];
    paymentIntentIds: { [providerId: string]: string } = {}; // Inicializa el diccionario vacío
    shippingPrice = 0;
}

export interface BasketTotals {
    shipping: number;
    subtotal: number;
    total: number;
}