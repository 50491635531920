import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { bannerIMG } from 'src/app/shared/models/bannerIMG';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }


  getBanners(): Observable<bannerIMG[]> {


  
    return this.http.get<bannerIMG[]>(this.baseUrl + 'profile').pipe(
      map(response => {
   

        return response;
      })
    )
  }
}
