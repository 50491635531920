<p-card styleClass="text-center" header="Recien Llegados">
    <p-carousel [value]="products" [numVisible]="1" [numScroll]="1"  [circular]="true" [responsiveOptions]="responsiveOptions" autoPlayInterval="3000">
        <ng-template let-product pTemplate="item">
            <div class="product-item">
                <div class="product-item-content">
                <div class="row mt-3">  
                    
                    <div class="row">
                        <div class="col-6">
                            <img src="{{ product.pictureUrl }}" [alt]="product.name" class="product-image" />
                        </div>   
                        <div class="col-6">
                            <img src="{{ product.pictureUrl }}" [alt]="product.name" class="product-image" />
                        </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <img src="{{ product.pictureUrl }}" [alt]="product.name" class="product-image" />
                      </div>
                      <div class="col-6">
                        <img src="{{ product.pictureUrl }}" [alt]="product.name" class="product-image" />
                      </div>
                    </div>
                </div>

                </div>
            </div>
        </ng-template>
    </p-carousel>
</p-card >

