<div class="security-and-shipping-container">

    <section class="section">
        <h2 class="section-title">Transacción Segura</h2>
        <div class="image-container">
            <img src="{{url}}5.jpg" alt="Imagen de Transacción Segura" />
        </div>
        <p>
            Todas las transacciones hechas en Viammex son monitoreadas para cumplir con los estándares de la página.
            Recuerda que los proveedores son verificados con diferentes parámetros que nos permiten comprobar su
            veracidad y seriedad a la hora de vender en nuestra página.  
        </p>
        <p>
            Nuestro Sello de Transacción Segura se tiene a partir de que se llega a un acuerdo con un proveedor de
            nuestra plataforma.
        </p>
        <p>
            El pago se hace a través de nuestra plataforma para que podamos ayudar en caso de cualquier suceso negativo
            para cualquiera de las partes.
        </p>
        <p>
            La transacción queda en Escrow hasta que se complete y se compruebe la conformidad de las dos partes, en ese
            momento se libera el monto a la parte vendedora.
        </p>
    </section>

    <section class="section">
        <h2 class="section-title">Logística</h2>
        <div class="image-container">
            <img src="{{url}}10.jpg" alt="Imagen de Logística" />
        </div>
        <p>
            En el tema de logística tenemos un socio aliado para hacer de tu experiencia más sencilla, rápida y
            confiable. Trabajamos con envia.com donde se pueden utilizar hasta 12 paqueterías diferentes para cotizar el
            mejor precio, tiempo y servicio.
        </p>
        <p>
            Una vez cotizado el pedido, podrás cotizar por tu parte el envío y decidir si se lo incluyes al cliente o si
            quieres absorber el gasto para dar un mejor servicio a tus clientes, es tu decisión. Ese mismo pedido con
            envio lo mandas directo al cliente para que decidan si aceptan o no, con el pago realizado.
        </p>
        <p>
            Ese pago es retenido hasta que se envíe el producto, donde recibirán un porcentaje de la venta, dejando
            pendiente otro porcentaje que se liberará cuando el cliente acepte el producto y las condiciones en las que
            viene al igual que cuando la paquetería nos avise que el producto ha sido entregado. 
        </p>
    </section>

</div>