<ng-container *ngIf="$any(bcService.breadcrumbs$ | async) as breadcrumbs">
    <section *ngIf="breadcrumbs[breadcrumbs.length-1].label !== 'Home'" class="py-1 mb-3" style="background-color: #f5f5f5;">
        <div class="container">
            <div class="row d-flex align-items-center">
                <div class="col-9">
                    <h1>{{breadcrumbs[breadcrumbs.length-1].label | titlecase}}</h1>
                </div>
                <div class="col-3">
                    <xng-breadcrumb>
                        <ng-container *xngBreadcrumbItem="let breadcrumb">
                            {{breadcrumb | titlecase}}
                        </ng-container>
                    </xng-breadcrumb>
                </div>
            </div>
        </div>
    </section>
</ng-container>

