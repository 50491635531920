import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-seguridad-envio',
  templateUrl: './seguridad-envio.component.html',
  styleUrls: ['./seguridad-envio.component.scss']
})
export class SeguridadEnvioComponent {

  url = environment.contentUrl + "images/pages/";


}
