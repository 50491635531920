import { Component } from '@angular/core';
import { Breadcrumb } from 'primeng/breadcrumb';
import { environment } from 'src/environments/environment';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-atencion-al-cliente',
  templateUrl: './atencion-al-cliente.component.html',
  styleUrls: ['./atencion-al-cliente.component.scss']
})
export class AtencionAlClienteComponent {

  url:string;

  constructor(private bcService: BreadcrumbService) {
      this.url = environment.contentUrl + "images/pages/7.jpg";

      
  }

}
