<div class="container mt-5">
    <h4>Internal server error - refreshing will make the error disappear</h4>
    <ng-container *ngIf="error">
        <h5 class="text-danger">Error: {{error.message}}</h5>
        <p class="font-weight-bold">This error comes from the server - not Angular</p>
        <p>What to do next?</p>
        <ol>
            <li>Check the network tab in chrome dev tools</li>
            <li>Reproduce the request in postman - if the same error occurs then definately not angular!</li>
        </ol>
        <h5>Stack trace</h5>
        <code class="mt-5" style="background-color: whitesmoke;">{{error.details}}</code>
    </ng-container>
</div>