<header class="navbar navbar-expand-lg navbar-light bg-white border-bottom shadow-sm fixed-top">
    <div class="container-fluid">
        <div class="flex-container">
            <div>
                <a class="navbar-brand m-auto" routerLink="/">
                    <img src="/assets/images/logo.jpg" alt="logo" class="logo mw-100" style="max-height: 4rem;">
                </a>

            </div>

            <div class="center-div">
                <a routerLink="/shop" routerlinkactive="active" class="p-2">MARKETPLACE</a>
            </div>

            <div class="d-flex align-items-center">
                <div class="dropdown mr-4" ngbDropdown>
                    <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" ngbDropdownToggle>
                        Acerca de
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end" ngbDropdownMenu aria-labelledby="navbarDropdown">
                        <li><a class="dropdown-item">About Us</a></li>
                        <li><a class="dropdown-item" routerLink="/seguridad-y-envio">Seguridad y Envíos</a></li>
                        <li><a class="dropdown-item" routerLink="/atencion-a-clientes">Atención al Cliente</a></li>
                    </ul>
                </div>

                <a routerLink="/basket" class="position-relative mt-2">
                    <i class="fa fa-shopping-cart fa-2x me-2" style="color: #00ADEF;"></i>
                    <div *ngIf="(basketService.basketSource$ | async) as basket" class="cart-no">
                        {{getCount(basket.items)}}
                    </div>
                </a>

                <ng-container *ngIf="(accountService.currentUser$ | async) === null">
                    <div class="dropdown" ngbDropdown>
                        <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" ngbDropdownToggle>
                            <mat-icon>person</mat-icon>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-end" ngbDropdownMenu aria-labelledby="navbarDropdown">
                            <li><a class="dropdown-item" routerLink="/account/login"><mat-icon>login</mat-icon> Iniciar
                                    sesión</a></li>
                            <li><a class="dropdown-item"
                                    routerLink="/account/register/client"><mat-icon>person_add</mat-icon> Registro
                                    Cliente</a></li>
                            <li><a class="dropdown-item"
                                    routerLink="/account/register/provider"><mat-icon>sell</mat-icon>
                                    Registro Vendedor</a></li>
                        </ul>
                    </div>
                </ng-container>

                <ng-container *ngIf="accountService.currentUser$ | async as user">
                    <div class="dropdown" ngbDropdown>
                        <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" ngbDropdownToggle>
                            <strong>{{user.fullName}}</strong>
                        </a>
                        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="navbarDropdown">
                            <li><a routerLink="/account/profile" class="dropdown-item"><i class="fa fa-user me-3"></i>
                                    Perfil</a></li>
                            <li><a routerLink="/basket" class="dropdown-item"><i class="fa fa-shopping-cart me-3"></i>
                                    Ver
                                    Cesta</a></li>
                            <li><a routerLink="/orders" class="dropdown-item"><i class="fa fa-history me-3"></i> Ver
                                    Pedidos</a></li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li><a (click)="accountService.logout()" class="dropdown-item"><i
                                        class="fa fa-sign-out me-3"></i> Cerrar Sesión</a></li>
                        </ul>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</header>