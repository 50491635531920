import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AccountService } from 'src/app/account/account.service';
import { BasketService } from 'src/app/basket/basket.service';
import { BasketItem } from 'src/app/shared/models/basket';


@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent {
  items: MenuItem[] = [];
  isMenuCollapsed = true; // This variable controls the collapse/expand state of the menu

  constructor(public basketService: BasketService, public accountService: AccountService) {

  

  }
  ngOnInit() {

  }

  logout() {
    this.accountService.logout();
  }

  getCount(items: BasketItem[]) {
    return items.reduce((sum, item) => sum + item.quantity, 0);
  }

}
