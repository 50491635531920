import { Component } from '@angular/core';
import { map, take } from 'rxjs';
import { Product } from 'src/app/shared/models/product';
import { ShopService } from 'src/app/shop/shop.service';

@Component({
  selector: 'app-new-arrivals',
  templateUrl: './new-arrivals.component.html',
  styleUrls: ['./new-arrivals.component.scss']
})
export class NewArrivalsComponent {


  products: Product[] = [];
  responsiveOptions: any[];


  constructor(private shopService: ShopService) {

    this.responsiveOptions = [
      {
          breakpoint: '1400px', // Extra grandes (escritorios grandes)
          numVisible: 1,       // Mostrar 1 slide completo a la vez
          numScroll: 1
      },
      {
          breakpoint: '1200px', // Grandes (escritorios y laptops grandes)
          numVisible: 1,
          numScroll: 1
      },
      {
          breakpoint: '992px',  // Medianos (laptops y tablets grandes)
          numVisible: 1,
          numScroll: 1
      },
      {
          breakpoint: '768px',  // Pequeños (tablets)
          numVisible: 1,       // Mostrar 1 slide completo
          numScroll: 1
      },
      {
          breakpoint: '576px',  // Extra pequeños (móviles)
          numVisible: 1,       // Mostrar 1 slide completo
          numScroll: 1
      }
  ];

  }

  ngOnInit(): void {  
    this.getProducts();
  }



  getProducts() {
    this.shopService.getProducts()
    
    .pipe(
      take(1),  // Take only the first emission (the initial product list)
      map(response => response.data.slice(0, 5)) // Select the first 5 elements
    )
    .subscribe({
      next: response => {
        this.products = response.sort((a, b) =>
        b.name.localeCompare(a.name));

       
      },
      error: error => console.log(error)
    })
  }




}
