<ngx-spinner type="timer">
    <h3>Loading...</h3>
</ngx-spinner>

<app-nav-bar></app-nav-bar>
<div >
<app-section-header ></app-section-header>

</div>

<router-outlet></router-outlet>